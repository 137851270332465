exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-en-design-js": () => import("./../../../src/pages/en/design.js" /* webpackChunkName: "component---src-pages-en-design-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-maps-js": () => import("./../../../src/pages/en/maps.js" /* webpackChunkName: "component---src-pages-en-maps-js" */),
  "component---src-pages-en-responsive-js": () => import("./../../../src/pages/en/responsive.js" /* webpackChunkName: "component---src-pages-en-responsive-js" */),
  "component---src-pages-en-seo-js": () => import("./../../../src/pages/en/seo.js" /* webpackChunkName: "component---src-pages-en-seo-js" */),
  "component---src-pages-en-social-js": () => import("./../../../src/pages/en/social.js" /* webpackChunkName: "component---src-pages-en-social-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maps-js": () => import("./../../../src/pages/maps.js" /* webpackChunkName: "component---src-pages-maps-js" */),
  "component---src-pages-responsive-js": () => import("./../../../src/pages/responsive.js" /* webpackChunkName: "component---src-pages-responsive-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */)
}

